.Flex {
    display: flex;
    align-items: center;

    & > *:not(:first-child) {
        margin-left: 1em;
    }
}

.Footer {
    composes: Flex;

    margin: 2em 0 1em;

    & img {
        width: 125px;
        border-radius: 25%;
    }
    & > div > a:before {
        content: '\00a0';
    }
}

.HeaderComponent {
    margin: 0.35em 0;
    font-size: 0.9em;
}

.Date {
    composes: HeaderComponent;

    color: var(--gray);
}

.Location {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.LocationDescription {
    composes: HeaderComponent;
}

.Submitter {
    composes: HeaderComponent;
    composes: Flex;
    margin: 1em 0;
    line-height: 1.5em;

    & img {
        height: 35px;
    }
    & div {
        align-self: flex-start;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}
