.Container {
    display: grid;
    gap: 2px;
    background-color: var(--gray-lighter);
    margin: 1em 0;
    border-width: 2px;
    border-style: solid;
    border-color: var(--gray-lighter);
}

.Header {
    margin: 0;
    padding: 0.75em;
    font-size: 1.1em;
    font-weight: normal;
    background-color: var(--color-blue-dark);
    color: white;
    border: 2px solid var(--color-blue-dark);
    margin: -2px -2px 0 -2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.FigureSet {
    --figure-span: 2;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-gap: 2px;
}

.Figure {
    grid-column-start: auto;
    grid-column-end: span var(--figure-span);
    display: flex;
    flex-direction: column;
    min-height: 190px;
    display: flex;
    flex-direction: column;
    margin: 0;
    color: var(--gray-darker);

    & figcaption {
        margin: 0;
        padding: 0.75em;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        background-color: var(--gray-lighter);
    }

    & > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.Half > .Figure {
    grid-column-end: span 2;

    &:nth-child(odd) {
        border-left: 2px solid var(--gray-lighter);
    }
}

.Quarter > .Figure {
    grid-column-end: span 1;

    &:not(:first-of-type) {
        border-left: 2px solid var(--gray-lighter);
    }
}

.SubSection {
    color: var(--gray-dark);
    background-color: white;
}

.Comment {
    composes: SubSection;

    & > * {
        margin: 0;

        &:not(:empty) {
            padding: 1em;
        }
    }
}

.Advice {
    composes: SubSection;

    & > div {
        padding: 1em;

        & > * {
            margin: 0;
        }
        & > ul {
            padding-left: 1.5em;
        }
    }
}
