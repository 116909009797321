.Dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s;

    &.Open {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }
}

.Inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    max-height: 100%;
    max-width: 100%;
    display: flex;
    align-content: stretch;
    flex-direction: column;
    background: white;
    border-radius: 4px;
}

.Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    font-weight: bold;
    background-color: var(--gray-lighter);
}

.Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1em;
}

.Body {
    flex: 1;
    padding: 1em;
    overflow-y: auto;
}
