.Submission {
}

.Observation {
}

.Section {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.Section--Content {
}

.Section--Title {
    color: var(--primary);
    text-transform: uppercase;
    padding-bottom: 0.75em;
    margin: 1em 0;
    border-bottom: 1px solid var(--gray-lighter);
}

.MapLocationLink {
    flex: 0 1 auto;
    align-self: center;
    margin-left: 1em;
}

.Activities {
    display: flex;
    align-items: center;
    font-size: 0.75em;

    & dt {
        display: none;
    }

    & dd {
        margin: 0;
    }
}

.ContextMap {
    height: 200px;

    & > :global(.mapboxgl-map) {
        height: 100%;
    }
}
