.loader {
    border: 10px solid white;
    border-top: 10px solid var(--primary);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
