.Content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Drawer {
    composes: Content;

    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 11;
    background: white;
    box-shadow: none;
    transition: transform 0.5s ease-out, box-shadow 0.5s ease-out;
    max-width: 500px;
    width: 100%;
}

.Left {
    composes: Drawer;

    left: 0;
    transform: translateX(-100%);

    &.Open {
        transform: translateX(0);
        box-shadow: 4px 0 8px color-mod(black alpha(25%));
    }
}

.Right {
    composes: Drawer;

    right: 0;
    transform: translateX(100%);

    &.Open {
        transform: translateX(0);
        box-shadow: -4px 0 8px color-mod(black alpha(25%));
    }
}

.Navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 5em;

    & > *:not(button) {
        padding: 0.5em;
    }

    & + .Header {
        padding-top: 0;
    }

    /* TODO: Super fragile selector, should be explicit.
        Like I did for the close button.
        For the sponsor image */
    & img {
        transform: scale(0.65);
    }
}

.Close {
    color: var(--primary);
    position: relative;
    right: 15px;
    z-index: 100;
}

.Header {
    padding: 1em 1.25em 0;

    & > h1 {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > :first-child {
            flex: 1 0;
        }
    }
}

.Body {
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior: contain;
    min-height: 0;
    padding: 0;
    border-top: solid 1px transparent;
}

.Subject {
    position: relative;
    margin: 0 1em;
    color: var(--brand-primary);
    font-size: 0.75em;
    /* line-height: 1.5; */
    font-weight: 700;
    text-transform: uppercase;

    & > span {
        background-color: white;
        padding-right: 1em;
    }

    &:before {
        position: absolute;
        top: 3px;
        right: 0;
        bottom: 3px;
        left: 0;
        z-index: -1;
    }
}

.Backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    background-color: color-mod(black alpha(25%));

    & + .Drawer {
        z-index: 12;
    }
}

.Banner {
    border: none;
    width: 100%;
    background-color: var(--gray-lightest);
    background-image: url('../../../styles/AvalanchePath.svg');
    background-repeat: no-repeat;
    background-position: center;
}
