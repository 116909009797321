.Container {
    position: relative;

    & table {
        position: inherit;

        & > thead {
            border-bottom: none;
        }

        & > tbody > tr > th:not([colspan]),
        & > thead > tr > th:first-child {
            position: absolute;
            top: auto;
            left: 0;
            width: 5em;
        }
    }
}

.Content {
    overflow-x: scroll;
    margin-left: 5em;
}
