.Map {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.LinkControlSet {
    position: absolute;
    left: 0.75em; /* Matches the hamburger menu */
    bottom: 120px;

    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: column;
}

.LinkControl {
    margin: 0.25em 0;
    padding: 0.25em;
    border-radius: 2em;
    width: 40px;
    height: 40px;
    box-shadow: 0 0 2px color-mod(black alpha(25%)), 0 2px 4px color-mod(black alpha(25%));
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;
    line-height: 1.1;
    white-space: nowrap;

    &:hover {
        & > img {
            opacity: 1;
        }
    }

    & > img {
        margin: 0 auto;
        height: 28px;
        opacity: 0.75;
    }

    & > span {
        padding-right: 0.5em;
    }
}

@media only screen and (max-width: 550px) {
    .MINLink {
        display: none;
    }
}

.Error {
    composes: LinkControl;

    border: none;
    background-color: var(--warning);
    cursor: pointer;
}

.ForecastMarker {
    cursor: pointer;
}

.visually-hidden {
    clip: rect(0 010 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.InfoButton {
    color: white;
    transition: all 0.3s ease; /* Ensures smooth return to default state */
    background: none;
    border-radius: 2em;
    padding: 0;
    border: none;
    font-size: 2.4em;
    width: 40px;
    height: 40px;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & > svg {
        opacity: 0.9;
    }

    &:hover {
        & > svg {
            opacity: 1;
        }
    }
}
