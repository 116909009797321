.Image {
    cursor: pointer;
}

.Caption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 1em;
    line-height: initial;
    color: white;
    white-space: normal;
    max-height: 15%;
    overflow-y: auto;
}
