.LocaleSwitcher {
    display: flex;
    flex-direction: row;
}

.Link {
    composes: import Primary from '../../components/button/Button.module.css';

    margin: 0.5em;

    &[aria-current='page'] {
        opacity: 0.65;
        pointer-events: none;
        cursor: cursor;
    }
}

.LocaleSwitcher a.Link {
    /* FIXME: shame alert: increased the specificity to make sure it override */
    color: white;
    text-decoration: none;
}
