.Menu {
    position: absolute;
    top: 0.75em;
    left: 0.75em;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 2em;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;

    &:hover {
        & > svg {
            opacity: 1;
        }
    }

    & > svg {
        height: 25px;
        width: 25px;
        color: var(--brand-primary);
        opacity: 0.75;
    }
}
