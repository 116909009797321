.Container {
}

.ChartSet {
    /* :not(:root) to make it selector stronger */
    & svg:not(:root) {
        overflow: visible;
    }
}

.UnavaliableMessage {
    text-align: center;
    font-size: 200%;
    margin: 2em;
}
