.Container {
    position: absolute;
    top: 0.8em;
    left: calc(39px + 0.5em + 0.75em); /* To match spacing between MWF/MIN buttons below */
    width: 280px;
}

.Control {
    border-radius: 2em;
    position: relative;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.Icon {
    position: absolute;
    top: 8px;
    left: 6px;
    opacity: 0.75;

    &:focus {
        opacity: 1;
    }
}

.Input {
    border-radius: 2em;
    padding: 0.125em 2em;
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    /* Remove default MUI input styles */
    & fieldset {
        border: none;
    }

    &:focus-within {
        border: 2px solid #4b89f0;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
        background-color: white;
    }
}

.Clear {
    border: none;
    background: transparent;
    position: absolute;
    top: 1px;
    right: 12px;
    padding: 0;
    font-size: 3em;
    line-height: 1;
}

.BoxWithShadow {
    font-size: 16px;
    background: var(--component-primary-bg-inactive);
    color: white;
    border-radius: 4px;
    margin-top: 8px;
    overflow: hidden;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.PaddedText {
    padding: 0.5em 0.75em;
}

.Option {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.5em 0.75em;

    &:hover {
        background-color: var(--component-primary-bg-hover);
    }
}

.Option--Selected {
    composes: Option;

    &,
    &:hover {
        background-color: var(--component-primary-bg-active);
    }
}

.archiveOption {
    padding: 12px 10px;
    border-bottom: 1px solid #ccc;
    width: 100%;
    cursor: pointer;
    z-index: 5;
    &:hover {
        background-color: #eee;
    }
}

.archiveOption--Selected {
    padding: 12px 10px;
    border-bottom: 1px solid #ccc;
    width: 100%;
    cursor: pointer;
    z-index: 5;
    background-color: #ccc;
}

.SearchHistory {
    font-style: italic;
    opacity: 0.7;
}

@media screen and (min-width: 640px) {
    .Container {
        top: 0.75em;
    }
    .Input {
        font-size: inherit;
        padding: 0.3em 2.5em;
    }
    .Clear {
        top: 4px;
        right: 12px;
        font-size: 2em;
    }
    .BoxWithShadow {
        font-size: inherit;
    }
}
