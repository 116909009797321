.LayerSet {
    margin: 0.5em 0;
}

.Layer {
    margin: 0;
    color: var(--gray);
    cursor: pointer;
    border-bottom: 1px solid var(--gray-lighter);

    &:hover {
        background-color: var(--gray-lightest);
    }
}

.Visible {
    composes: Layer;

    color: black;

    & .Header {
        & > svg:first-child {
            & path {
                fill: black;
            }
        }
        & > img {
            opacity: 1;
        }
    }
}

.Disabled {
    cursor: default;
}

.Header {
    padding: 0.5em 1em;
    display: flex;
    align-items: center;

    & > svg:first-child {
        flex: 0 0 24px;
        & path {
            fill: var(--gray);
        }
    }
    & > img {
        opacity: 0.15;
    }
}

.Content {
    padding: 0.5em 1em;
}

.Title {
    flex: 100%;
    margin: auto 0 auto 0.5em;
}

.FilterSet {
    margin: 0.25em 0;

    & > * {
        margin: 0.25em 0;
    }
}
