.Figure {
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 250px;

    & img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        opacity: 1;
        transition: opacity 1s;
        height: auto;
    }
}

:global(.image-gallery .fullscreen) .Figure img {
    max-width: 100vw;
    max-height: 100vh;
    height: auto;
    object-fit: contain;
}

.Pending {
    & img {
        opacity: 0;
    }

    background: var(--gray-lighter) url('../../../styles/AvalanchePath.svg') center 25% no-repeat;
}

.Resolved {
}

.Rejected {
    & img {
        background-color: var(--error);
        color: white;
    }
}

.Caption {
    padding: 2em 2em 50px 2em;
    margin: 0;
    background-color: black;
    color: white;
}
